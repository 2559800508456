
import { Component, Vue } from 'vue-property-decorator'
import { apiWorkbenchIndex, apiWorkbenchTopAmount, apiWorkbenchTopUser } from '@/api/home'
import * as echarts from 'echarts'

@Component
export default class Home extends Vue {
    indexData: object = {
        platform_info: {},
        accumulated_data: {}
    }

    topAmount: Array<Object> = []
    topAmountCount = 0

    topUser: Array<Object> = []
    topUserCount = 0

    iconArr = [
        require('@/assets/images/icon/1.png'),
        require('@/assets/images/icon/2.png'),
        require('@/assets/images/icon/3.png'),
        require('@/assets/images/icon/4.png')
    ]

    orders = {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['营业额', '商品数量', '订单数量']
        },
        xAxis: {
            type: 'category',
            data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            splitLine: {
                show: true, // 是否显示分隔线。默认数值轴显示，类目轴不显示
                interval: '1' // 坐标轴刻度标签的显示间隔，在类目轴中有效.0显示所有
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: '营业额',
                type: 'line',
                stack: '总量',
                data: []
            },
            {
                name: '商品数量',
                type: 'line',
                stack: '总量',
                data: []
            },
            {
                name: '订单数量',
                type: 'line',
                stack: '总量',
                data: []
            }
        ]
    }

    business = {
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['营业额', '商品数量', '订单数量']
        },
        xAxis: {
            type: 'category',
            data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            splitLine: {
                show: true, // 是否显示分隔线。默认数值轴显示，类目轴不显示
                interval: '1' // 坐标轴刻度标签的显示间隔，在类目轴中有效.0显示所有
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: '营业额',
                type: 'line',
                stack: '总量',
                data: []
            },
            {
                name: '商品数量',
                type: 'line',
                stack: '总量',
                data: []
            },
            {
                name: '订单数量',
                type: 'line',
                stack: '总量',
                data: []
            }
        ]
    }

    // 获取工作台数据
    async getWorkbenchIndexData(): Promise<void> {
        const res: object = await apiWorkbenchIndex()
        this.indexData = res
    }

    processInput(input: any) {
        if (typeof input === 'string' && input.includes('{') && input.includes('}')) {
            try {
                const obj = JSON.parse(input)
                return obj.zh_cn
            } catch (error) {
                return input
            }
        } else if (typeof input === 'string') {
            return input
        } else {
            return 'Invalid input'
        }
    }

    // 获取工作台营业额排名
    async getWorkbenchTopAmountData(page: number): Promise<void> {
        const res: object = await apiWorkbenchTopAmount({
            page_no: page,
            page_size: 10
        })
        this.topAmount = (res as any).lists
        this.topAmountCount = (res as any).count
    }

    // 获取工作台商城排名
    async getWorkbenchTopUserData(page: number): Promise<void> {
        const res: object = await apiWorkbenchTopUser({
            page_no: page,
            page_size: 10
        })
        this.topUser = (res as any).lists
        this.topUserCount = (res as any).count
    }

    goPage(url: string): void {
        this.$router.push(url)
    }

    created(): void {
        this.getWorkbenchIndexData()
        this.getWorkbenchTopAmountData(1)
        this.getWorkbenchTopUserData(1)
    }
    mounted() {
        const orderContainer = document.getElementById('order') as HTMLDivElement
        let orderChart = echarts.init(orderContainer)
        orderChart.setOption(this.business)
        const businessContainer = document.getElementById('business') as HTMLDivElement
        let businessChart = echarts.init(businessContainer)
        businessChart.setOption(this.business)
    }
}
